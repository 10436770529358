export default function Email({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="title"
      className={className}
    >
      <title id="title">Email</title>
      <path
        d="M21.344 1H2.65602C1.46529 1 0.5 2.00733 0.5 3.25V16.75C0.5 17.9926 1.46529 19 2.65602 19H21.344C22.5347 19 23.5 17.9926 23.5 16.75V3.25C23.5 2.00733 22.5347 1 21.344 1ZM22.7813 16.4166L15.9831 9.322L22.7813 4.80115V16.4166ZM2.65602 1.75H21.344C22.1365 1.75 22.7813 2.42286 22.7813 3.25V3.9124L12.8868 10.4925C12.3513 10.8485 11.6673 10.8375 11.1441 10.4662L1.21867 3.42606V3.25C1.21867 2.42286 1.8635 1.75 2.65602 1.75ZM1.21867 4.33195L8.11279 9.22191L1.21867 16.4166V4.33195ZM21.344 18.25H2.65602C2.06405 18.25 1.55505 17.8742 1.33506 17.3395L8.70861 9.64454L10.7406 11.0858C11.1308 11.3627 11.5821 11.5015 12.034 11.5015C12.4636 11.5015 12.8938 11.3762 13.2724 11.1246L15.3718 9.7285L22.6649 17.3395C22.445 17.8742 21.936 18.25 21.344 18.25Z"
        strokeWidth="0.5"
      />
    </svg>
  );
}
