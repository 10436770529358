import { ReactHTML } from 'react';
import { twMerge } from 'tailwind-merge';

export default function Heading({
  level = 1,
  styleLevel,
  className,
  headingClasses,
  children,
  ...otherProps
}: {
  level?: number;
  styleLevel?: number;
  className?: string;
  headingClasses: { [key: string]: string };
  children: React.ReactNode;
}): JSX.Element {
  const HeadingTag = `h${level}` as keyof ReactHTML;
  const classes: string = headingClasses[styleLevel || level];

  return (
    <HeadingTag className={twMerge(classes, className)} {...otherProps}>
      {children}
    </HeadingTag>
  );
}
