'use client';

import { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';

import Spinner from '@/components/map/spinner.component';
import type { MapProps } from '@/components/map/map.component';
import { useSelector } from 'react-redux';
import { GlobalConfig, MapConfig, Section, selectThemePreferences } from '@homeflow/next/state';

// @ts-ignore - argument types for dynamic appear to be treat the options as the first argument instead of import callback
const Map = dynamic<MapProps>(() => import('@/components/map/map.component.tsx'), {
  ssr: false,
  suspense: true,
  loading: () => (
    <div className="bg-gray-300 w-full h-full">
      <Spinner />
    </div>
  ),
});

export default function LazyMap({
  editMode = false,
  marker,
  section,
  globalConfig,
}: {
  editMode?: boolean;
  marker: { lat: number; lng: number };
  section: Section<MapConfig>;
  globalConfig: GlobalConfig;
}) {
  const preferences = useSelector(selectThemePreferences);
  // lazy-load the map only when it comes into view to avoid loading Leaflet before it's needed
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) setLoaded(true);
  }, [inView]);

  const mapType = useMemo(
    () =>
      section.configuration.mapType === 'google' && !!preferences.googleMapsApiKey
        ? 'google'
        : 'leaflet',
    [preferences.googleMapsApiKey, section.configuration.mapType]
  );

  return (
    <div className="rigel-map-section h-[500px]" ref={ref}>
      {loaded && (
        <Map
          markers={[{ ...marker, id: '1' }]}
          mapType={mapType}
          editMode={editMode}
          globalConfig={globalConfig}
        />
      )}
    </div>
  );
}
