import Loader from '@/components/loader/loader.component';

export default function SendingMessage() {
  return (
    <div className="flex flex-col gap-5 min-h-[200px] justify-center">
      <div className="flex flex-row justify-center">
        <Loader className="w-12 h-12" />
      </div>

      <p className="text-center text-gray-700">Sending your message...</p>
    </div>
  );
}
