import { UseFormRegister } from 'react-hook-form';

import { CustomFormParams, FormElement as FormElementType } from '@homeflow/next/models';

import Input from '@rigel/components/forms/input.component';
import Label from '@rigel/components/forms/label.component';
import Select from '@rigel/components/forms/select.component';
import Textarea from '@rigel/components/forms/textarea.component';
import Heading from '@rigel/components/heading.component';
import Radio from '../../../components/forms/radio.component';

export default function FormElement({
  element,
  formId,
  register,
}: {
  element: FormElementType;
  formId: string;
  register: UseFormRegister<CustomFormParams>;
}) {
  if (element.form_field_type === 'label') {
    return (
      <div className="col-span-2">
        <Heading level={3}>{element.friendly_name}</Heading>
      </div>
    );
  }

  const renderControl = () => {
    switch (element.form_field_type) {
      case 'select':
        return (
          <Select
            className="mb-4"
            id={`${formId}_${element.field_name}`}
            required={element.is_required}
            {...register(element.field_name, {
              required: element.is_required,
            })}
          >
            {element.field_options?.split('|').map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        );
      case 'textarea':
        return (
          <Textarea
            className="mb-4"
            id={`${formId}_${element.field_name}`}
            rows={4}
            required={element.is_required}
            {...register(element.field_name, {
              required: element.is_required,
            })}
          />
        );
      case 'optgroup':
        return (
          <Radio
            className="mb-4"
            id={`${formId}_${element.field_name}`}
            required={element.is_required}
            options={element.field_options?.split('|') || []}
            {...register(element.field_name, {
              required: element.is_required,
            })}
          />
        );
      case 'input':
        return (
          <Input
            className="mb-4"
            id={`${formId}_${element.field_name}`}
            required={element.is_required}
            {...register(element.field_name, {
              required: element.is_required,
            })}
          />
        );
      default:
        return <>Have not yet got the abiltiy to work with {element.form_field_type}</>;
    }
  };

  return (
    <div className="flex flex-col">
      <Label htmlFor={`${formId}_${element.field_name}`}>{element.friendly_name}</Label>
      {renderControl()}
    </div>
  );
}
