import { InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Label from './label.component';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  options: string[];
}

// forwardRef is required here as react-hook-form passes a ref to
// form fields that would otherwise break function components
const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ options, id, className, ...otherProps }: RadioProps, ref) => {
    return (
      <div className="flex gap-8 mb-4">
        {options.map((option, index) => {
          return (
            <div key={option} className="flex flex-row gap-2 items-center">
              <input
                id={`${id}_${index}`}
                className={twMerge(className)}
                ref={ref}
                type="radio"
                {...otherProps}
              />
              <Label htmlFor={`${id}_${index}`} className="mb-4">
                {option}
              </Label>
            </div>
          );
        })}
      </div>
    );
  }
);

// prevents linting error 'Component definition is missing display name'
Radio.displayName = 'Radio';

export default Radio;
