import { cmsImage } from '@homeflow/next/images';

export default function ImageBannerFixed({ image }: { image: { src: string } }) {
  return (
    <div
      style={{
        backgroundImage: `url(${
          image.src.startsWith('/images/') ? image.src : cmsImage(image.src)
        })`,
      }}
      className={`bg-fixed bg-cover h-full w-full`}
    ></div>
  );
}
