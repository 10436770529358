import { ILead } from '@homeflow/next/models';
import { BaseSyntheticEvent } from 'react';
import { toast } from 'react-toastify';

export default async function submitLeadForm<T extends ILead>(
  submitHandler: (params: T) => any,
  {
    params,
    event,
    action,
  }: {
    params: T;
    event?: BaseSyntheticEvent;
    action?: string;
  }
) {
  try {
    grecaptcha.ready(async () => {
      const token = await grecaptcha.execute(process.env.NEXT_PUBLIC_CAPTCHA_VERIFY_API_SITE_KEY!, {
        action: action || 'submit',
      });

      if (token) {
        params.captchaToken = token;
        sendGAEvent(event);
      }

      submitHandler(params);
    });
  } catch (e) {
    toast.error('Sorry, something went wrong.');
    console.error(e);
  }
}

export function sendGAEvent(e?: BaseSyntheticEvent) {
  if (typeof window === 'undefined' || !e) return;

  const form = e.target as HTMLFormElement;

  const name = form.getAttribute('data-form-name');
  const branchId = form.getAttribute('data-branch-id');
  const action = form.getAttribute('data-action') || 'submitSuccess';
  const event = form.getAttribute('data-event') || 'bp.formInteraction';

  if (!name) return;

  const payload: GAPayload = {
    event,
    'tracker-version': 'blueprint',
  };

  if (event === 'bp.formInteraction') {
    payload.formName = name;
    payload.formAction = action;
  } else {
    payload.name = name;
    payload.action = action;
  }

  if (branchId) {
    payload.branchId = branchId;
  }

  window.dataLayer?.push(payload);
}

type GAPayload = {
  event: string;
  'tracker-version': string;
  formName?: string;
  formAction?: string;
  name?: string;
  action?: string;
  branchId?: string;
};
