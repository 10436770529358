import { ComponentPropsWithRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { inputClasses } from './input.component';

interface Props extends ComponentPropsWithRef<'select'> {
  className?: string;
}

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ className, children, ...otherProps }: Props, ref) => {
    return (
      <div
        className={twMerge(
          'bg-white border border-gray-400 relative w-full after:-translate-y-1/2 after:origin-top after:absolute after:h-2 after:w-2 after:border-r-2 after:border-b-2 after:border-primary after:rotate-45 after:top-1/2 after:right-3 after:pointer-events-none',
          className
        )}
      >
        <select
          className={twMerge(inputClasses, 'relative bg-transparent appearance-none border-0')}
          {...otherProps}
          ref={ref}
        >
          {children}
        </select>
      </div>
    );
  }
);

Select.displayName = 'Select';

export default Select;
