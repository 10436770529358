'use client';

import { FunctionComponent, PropsWithChildren } from 'react';

import { SectionProps } from '@homeflow/next/sections';
import { CarouselConfig } from '@homeflow/next/state';

import ImageCarouselSectionText from './section-text/image-carousel-section-text.component';
import ImageCarouselSlideText from './slide-text/image-carousel-slide-text.component';
import Container from '../../components/container.component';
import { SectionItemWrapperProps } from '@/components/section-items/section-item-wrapper.component';

export default function ImageCarousel(
  props: PropsWithChildren &
    SectionProps<CarouselConfig> & {
      SectionItemWrapper?: FunctionComponent<SectionItemWrapperProps>;
    }
) {
  return (
    <Container containerWidth={props.section.configuration.containerWidth}>
      {(() => {
        switch (props.section.configuration.variant) {
          case 'sectionText':
            return <ImageCarouselSectionText {...props} />;
          case 'slideText':
            return <ImageCarouselSlideText {...props} />;
          default:
            return null;
        }
      })()}
    </Container>
  );
}
