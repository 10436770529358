import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { SectionWrapperProps } from '@homeflow/next/sections';
import { GenericConfig, RichTextConfig } from '@homeflow/next/state';
import { getItem } from '@/utils/get-item';

export default function RichTextElement({
  section,
  jsx,
  elementIndex = 0,
  itemIdChain,
  className,
}: SectionWrapperProps<RichTextConfig> & {
  jsx?: JSX.Element;
  elementIndex: number;
  itemIdChain?: string[];
  className?: string;
}) {
  // If inside section item, grab content from item
  const item = useMemo(
    () => getItem(section.configuration?.items as GenericConfig[], itemIdChain),
    [itemIdChain, section.configuration]
  );

  if (jsx) return <span className={twMerge(className)}>{jsx}</span>;

  const content = item
    ? item.elements?.[elementIndex]?.html
    : section.configuration?.elements?.[elementIndex]?.html;

  return (
    <span
      className={twMerge('relative text-inherit', className)}
      dangerouslySetInnerHTML={{ __html: content || '' }}
    />
  );
}
