'use client';

import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export default function TabbedContent({
  tabs,
  children,
}: {
  tabs: { id: string; tab: React.JSX.Element }[];
  children: React.ReactNode[];
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [dropDownExpanded, setDropDownExpanded] = useState(false);

  const selectTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
    setDropDownExpanded((prev) => {
      return !prev;
    });
  };

  return (
    <>
      <div className="bg-grey-alternative @md:bg-transparent">
        <div
          className={twMerge(
            'pt-10 @md:pt-0 flex flex-col @md:flex-row @md:gap-1 @md:items-end border-t-4 border-solid border-primary @md:border-0',
            'relative @md:after:hidden after:transition-all after:origin-[5px_5px] after:absolute after:h-2 after:w-2 after:border-r-2 after:border-b-2 after:border-primary after:top-4 after:right-3 z-0 after:-z-10',
            dropDownExpanded ? 'after:rotate-[-135deg]' : 'after:rotate-45'
          )}
        >
          {tabs.map((tab, index) => (
            <button
              key={tab.id}
              className={twMerge(
                'height-10 py-2 px-12 border-b-2 border-white @md:border-0',
                index === activeTab
                  ? 'absolute top-0 inset-x-0 @md:relative @md:bg-grey-alternative @md:border-t-4 @md:border-solid @md:border-primary font-bold'
                  : 'bg-custom-3',
                index !== activeTab && !dropDownExpanded && 'hidden @md:block'
              )}
              onClick={() => selectTab(index)}
            >
              {tab.tab}
            </button>
          ))}
        </div>
      </div>

      <div className="p-5 @md:p-14 bg-grey-alternative mb-14">
        {children && children[activeTab]}
      </div>
    </>
  );
}
