import ResponsiveImage from '@homeflow/next/components/images/responsive-image';
import { twMerge } from 'tailwind-merge';
import { SectionItemWrapperProps } from '@/components/section-items/section-item-wrapper.component';
import { FunctionComponent } from 'react';
import { SectionProps } from '@homeflow/next/sections';
import { CustomFormConfig, GenericConfig } from '@homeflow/next/state';
import { generateSettings, initialFormCard } from '../initial-form-card';
import SectionElement from '../../section-element.component';

export default function FormCard({
  section,
  className,
  editMode,
  SectionElementWrapper,
  SectionItemWrapper,
}: SectionProps<CustomFormConfig> & {
  className?: string;
  SectionItemWrapper?: FunctionComponent<SectionItemWrapperProps>;
}) {
  const item = section.configuration.items?.[0] as GenericConfig;

  const renderContent = () => {
    return (
      <div
        className={twMerge(
          'rigel-branch-card flex flex-col items-stretch w-full',
          !SectionItemWrapper && className
        )}
      >
        <div className={`relative h-[20rem] @md:flex-1`}>
          {item.image && (
            <ResponsiveImage
              image={{ src: item.image.src, alt: item.image.alt }}
              dimensions={[{ height: 398, width: 398 }]}
              className="h-full w-full object-cover absolute"
            />
          )}
        </div>
        <div className="bg-striped p-8 h-fit flex flex-col gap-4 overflow-y-hidden">
          {item.elements?.map((element, index) => (
            <SectionElement
              key={element.id}
              element={element}
              elementIndex={index}
              editMode={editMode}
              itemIdChain={[item.id]}
              section={section}
              baseClassName="rigel-form-card"
              SectionElementWrapper={SectionElementWrapper}
            />
          ))}
        </div>
      </div>
    );
  };

  return SectionItemWrapper ? (
    <SectionItemWrapper
      className={twMerge('flex', className)}
      item={item}
      itemIdChain={[item.id]}
      section={section}
      generateSettings={generateSettings}
      sectionConfig={section.configuration}
      defaultInitialItem={{ ...initialFormCard() }}
      hideControls
    >
      {renderContent()}
    </SectionItemWrapper>
  ) : (
    renderContent()
  );
}
