import { ContainerWidth, ElementType, MixedSection } from '@homeflow/next/state';
import { v4 as uuid } from 'uuid';

export const initialSlide = (): MixedSection => ({
  id: uuid(),
  elements: [
    { id: uuid(), type: ElementType.Heading, html: 'Heading', level: 2 },
    { id: uuid(), type: ElementType.Text, html: 'Content' },
  ],
  containerWidth: ContainerWidth.Full,
  image: {
    src: '/images/home-hero-default.jpg',
    alt: '',
    hidden: false,
  },
  link: { html: 'Link button', target: '_self', url: '/', hidden: false },
});

export default initialSlide;
