import getAgency from '@/actions/get-agency';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { CustomForm as CustomFormModel, CustomFormParams } from '@homeflow/next/models';
import { SubmitHandler } from 'react-hook-form';
import { submitCustomFormSubmission } from '@/app/actions';
import { CustomFormConfig, Section } from '@homeflow/next/state';
import { toast } from 'react-toastify';
import submitLeadForm from '@/utils/submit-lead-form';

enum Status {
  Loading = 'loading',
  Idle = 'idle',
  Success = 'success',
  Error = 'error',
}

export default function useCustomForm({
  section,
}: {
  section: Section<CustomFormConfig> & { areaName: string };
}) {
  const [forms, setForms] = useState<CustomFormModel[]>([]);
  const [loadingForms, setLoadingForms] = useState(true);
  const [status, setStatus] = useState<Status>(Status.Idle);

  useEffect(() => {
    async function setData() {
      const agency = await getAgency();
      if (!agency || !agency.customForms) {
        setLoadingForms(false);
        return null;
      }
      setForms(agency.customForms);
      setLoadingForms(false);
    }

    setData();
  }, []);

  const selectedForm = section.configuration.formId
    ? forms.find((form) => form.id === +section.configuration.formId)
    : forms[0];

  const onSubmit: SubmitHandler<CustomFormParams> = async (
    params: CustomFormParams,
    event?: BaseSyntheticEvent
  ) => {
    setStatus(Status.Loading);

    const handleLeadSubmission = async (formData: CustomFormParams) => {
      const response = await submitCustomFormSubmission({
        form_params: {
          redirect_url: '/',
          form_submission: {
            ...formData,
          },
        },
        form_id: selectedForm?.id,
      });

      // Usually returned with lead creation / validation error
      if (response.errors) {
        setStatus(Status.Error);
        toast.error(`Sorry, something went wrong: ${response.errors?.[0].message}`);
        console.error(response.errors);
        return;
      }

      // Returned with internal HF error - site/form not found etc....
      // Only log these error messages, don't show to User
      if (response.message) {
        setStatus(Status.Error);
        toast.error(`Sorry, something went wrong`);
        console.error(response.message);
        return;
      }

      setStatus(Status.Success);
      toast.success('Thank you. We have sent your message.');
    };

    submitLeadForm<CustomFormParams>(handleLeadSubmission, {
      event,
      params,
      action: 'custom_form',
    });
  };

  return {
    loadingForms,
    status,
    forms,
    onSubmit,
    selectedForm,
  };
}
