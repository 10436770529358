import ResponsiveImage from '@homeflow/next/components/images/responsive-image';

export default function ImageBannerStatic({
  image,
  editMode = false,
}: {
  image: { src: string; alt: string; lazy?: boolean };
  editMode?: boolean;
}) {
  return (
    <div className="h-full w-full" {...(editMode && { onDragStart: (e) => e.preventDefault() })}>
      <ResponsiveImage
        image={image}
        options={{
          loading: image.lazy ? 'lazy' : 'eager',
          priority: !image.lazy,
        }}
      />
    </div>
  );
}
