'use client';

import { PropsWithChildren } from 'react';

import { ContainerWidth, ImageField } from '@homeflow/next/state';

import Container from '../container.component';
import ImageBannerFixed from './image-types/image-banner-fixed.component';
import ImageBannerStatic from './image-types/image-banner-static.component';
import { twMerge } from 'tailwind-merge';

export default function ImageBanner({
  image,
  editMode = false,
  overlayOpacity = 0.5,
  children,
  height,
}: PropsWithChildren & {
  image?: ImageField;
  editMode?: boolean;
  overlayOpacity?: number;
  height?: string;
}) {
  const renderImage = () => {
    if (!image) return null;

    switch (image.type) {
      case 'fixed':
        return <ImageBannerFixed image={image} />;
      default:
        return <ImageBannerStatic image={image} editMode={!!editMode} />;
    }
  };
  const hasChildren = !!(children && Array.isArray(children) && children.length > 0);
  return (
    <div
      className={twMerge(
        'rigel-image-banner relative mx-auto overflow-hidden group/section',
        hasChildren && 'pb-9',
        height === 'small' && 'max-h-[50svh] @md:max-h-none h-64 @sm:h-[400px]',
        (height === 'medium' || !height) && 'max-h-[70svh] @md:max-h-none h-96 @sm:h-[600px]',
        height === 'large' && 'max-h-[90svh] @md:max-h-none h-[40rem] @sm:h-[800px]'
      )}
    >
      <div className="relative w-full h-full">
        {renderImage()}
        {overlayOpacity > 0 && (
          <div
            className="rigel-image-banner__background-overlay absolute top-0 left-0 block w-full h-full bg-black"
            style={{ opacity: overlayOpacity }}
          />
        )}
      </div>

      {hasChildren && (
        <Container containerWidth={ContainerWidth.Medium}>
          <div className="rigel-image-banner__text-overlay z-20 absolute -bottom-9 left-0 block bg-striped @lg:max-w-[50%]">
            <div className="flex flex-col gap-9 w-full p-9 items-start">{children}</div>
          </div>
        </Container>
      )}
    </div>
  );
}
